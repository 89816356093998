import React from 'react'
import RichText from '../utils/richTextUtils'
import Img from 'gatsby-image'

function PlaylistPage({ pageContext: { playlist } }: Props) {
  const { title, description, items } = playlist

  return title && items?.length ? (
    <div className="my-8 sm:mx-16 mx-8">
      <h1 className="text-3xl">{title}</h1>
      {description?.json ? (
        <div className="mt-4">
          <RichText richTextDocument={description.json} />
        </div>
      ) : null}
      <div className="sm:mx-10 my-10 flex flex-col">
        {items.map((item) => (
          <div key={item.contentful_id} className="h-20 mb-4">
            {/* TODO: The react-native mux player uses mjs and isn't working right with Gatsby. For now, we'll use a static*/}
            {/*       player for videos and just link to it with the video playback ID.*/}
            {/* <Link*/}
            {/*   to={`/audio-video/${item.contentful_id}`}*/}
            {/*   // target="_blank"*/}
            {/*   // className="transition duration-300 ease-in-out border-b-8 border-transparent cursor-pointer hover:border-gp-violet hover:no-underline hover:opacity-75"*/}
            {/* >*/}
            <a
              href={`/vidplayer?playbackId=${item.muxVideo.playbackId}`}
              target="_blank"
              className="transition duration-300 ease-in-out border-b-8 border-transparent cursor-pointer hover:border-gp-violet hover:no-underline hover:opacity-75"
            >
              <div className="h-20 mb-4 flex flex-row bg-brand-pale-grey">
                <div className="mr-4 w-1/4">
                  <Img
                    className="h-full w-full"
                    imgStyle={{ objectFit: 'contain' }}
                    fluid={item.stillImage.fluid}
                    title={item.stillImage.title}
                  />
                </div>
                <div className="w-3/4 flex flex-col justify-center">
                  <h2>{item.title}</h2>
                  {item.description ? (
                    <p>
                      <RichText richTextDocument={item.description.json} />
                    </p>
                  ) : null}
                </div>
              </div>
            </a>
            {/* </Link> */}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <p>No playlist data found!</p>
  )
}

interface Props {
  pageContext: {
    playlist: {
      contentful_id: string
      title: string
      description: {
        json: object
      }
      stillImage: {
        title: string
        fluid: any
      }
      items: {
        contentful_id: string
        title: string
        description: {
          json: object
        }
        stillImage: {
          title: string
          fluid: any
        }
        muxVideo: {
          id: string
          assetId: string
          playbackId: string
          audioOnly: boolean
          duration: number
          max_stored_frame_rate: number
          max_stored_resolution: string
          ratio: string
          ready: boolean
          version: number
          uploadId: string
        }
      }[]
    }
  }
}

// noinspection JSUnusedGlobalSymbols
export default PlaylistPage
